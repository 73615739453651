import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, withStyles } from "@material-ui/core"
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react"
import { useStyle } from "../../hooks/use-style"
import { darkTheme, lightTheme } from "../../services/theme"
import { Channel, Claim } from "../../types"
import SubmitButton from "../layout/form/submit-button"
import { navigate } from "gatsby"
import Avatar from "../layout/avatar"
import Notice from "../widgets/notice"
import { TagsSelect } from "react-select-material-ui"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import Select from "../layout/form/select"
import { useNode } from "../../hooks/use-node"
import { showToast } from "../status-notification"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"

type Props = {
  channel: Channel
  close: () => void
}

const JoinSuperchannel: FunctionComponent<Props> = ({ channel, close }) => {
  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const { user } = useUser()
  const { rpc } = useNode()
  const [superchannel, setSuperchannel] = useState("")
  const onChannelChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setSuperchannel(event.currentTarget.value.id)
    },
    []
  )

  const subscriptions = useMemo(() => {
    if (user) {
      let v = []
      for (let i = 0; i < user.subscriptions.length; i++) {
        if (
          user.subscriptions[i].id.indexOf("!") != -1 &&
          user.subscriptions[i].id != channel.id &&
          user.subscriptions[i].id != channel.id + "!"
        ) {
          v.push({
            label: user.subscriptions[i].title,
            id: user.subscriptions[i].id,
          })
        }
      }
      return v
    } else return []
  }, [user?.subscriptions])

  const join = useCallback(() => {
    let sc =
      superchannel.length != 0
        ? superchannel
        : subscriptions.length != 0
        ? subscriptions[0].id
        : ""
    if (sc.length != 0) {
      if (sc.indexOf("!") > 0) {
        sc = sc.substring(0, sc.length - 1)
      }
      console.log(channel.id + " => " + sc)

      let payload = {
        alias: channel.id,
        superchannel: sc,
        pro: true,
      }

      rpc(
        "link_channel",
        JSON.stringify(payload),
        user,
        getCachedSecret(),
        function (res) {
          let message
          if (res.status == "ok") {
            message = "Link sent for moderation. Thank you!"
            //if (user) updateBalance(-0.01) // todo: update
          } else message = "Edit failed"

          showToast(faInfoCircle, "Smartlike network", message)
        }
      )
    }
    close()
  }, [superchannel, user])

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div onClick={close} className="close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <>
        <h1>Link to Super Channel</h1>

        <div style={{ marginBottom: "10px", fontSize: "16px" }}>
          Please select a channel which is authored by the same creator as
          <br />
          <b>{channel.title}</b> on <b>{channel.alias_host}</b>:
        </div>

        <Select
          value={superchannel}
          onChange={onChannelChange}
          style={{ width: "100%" }}
        >
          {subscriptions.map(c => (
            <option key={c.id} value={c.id}>
              {c.label}
            </option>
          ))}
        </Select>

        <footer>
          <SubmitButton onClick={join}>Submit</SubmitButton>
        </footer>
      </>

      <style jsx>{`
        .owner {
          width: 100%;
          display: flex;
          margin-bottom: 10px;
          margin-top: 10px;
          padding: 5px;
          /*background-color: ${css.backgroundColor};*/
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;
        }

        .handle {
          color: rgb(101, 119, 134);
          margin-left: 4px;
        }
        strong {
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          background-color: rgba(105, 105, 105, 0.05);
          padding: 5px;
          border-radius: 5px;
        }
        strong:hover {
          background-color: rgba(105, 105, 105, 0.2);
          cursor: pointer;
        }

        .inline {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
        }
        .close:hover {
          cursor: pointer;
        }
        .highlighted {
          font-weight: 400;
          font-size: 14px;
        }

        .amountCell {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          width: 80px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
          margin-top: 10px;
        }
      `}</style>
    </section>
  )
}

export default JoinSuperchannel
