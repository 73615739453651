import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, withStyles } from "@material-ui/core"
import React, { FunctionComponent, useCallback } from "react"
import { useStyle } from "../../hooks/use-style"
import { darkTheme, lightTheme } from "../../services/theme"
import { Channel, Claim } from "../../types"
import SubmitButton from "../layout/form/submit-button"
import { navigate } from "gatsby"
import Avatar from "../layout/avatar"
import Notice from "../widgets/notice"

type Props = {
  channel: Channel
  close: (arg0: Channel | null) => void
}

const SubscribeSuperchannel: FunctionComponent<Props> = ({
  channel,
  close,
}) => {
  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  //const title = channel.id.indexOf(".") != -1 ? channel.id : channel.alias_host
  const isDomain = channel.id.indexOf(".") != -1

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            close(null)
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <>
        <h1>Subscription options</h1>

        <div style={{ marginBottom: "10px" /*, fontSize: "16px"*/ }}>
          Would you like to subscribe to{" "}
          {isDomain ? (
            <b>{channel.id}</b>
          ) : (
            <>
              <b>{channel.title}</b> on <b>{channel.alias_host}</b>
            </>
          )}{" "}
          or to the Super Channel that aggregates their publications on all
          platforms?
        </div>
        <Notice id="superchannel" submit="Got it">
          <a
            href="https://blog.smartlike.org/platform-independent-creator-channels"
            target="_blank"
          >
            Super Channels
          </a>{" "}
          are linked either by creators who{" "}
          <a href="https:/blog.smartlike.org/how-to-cash-in" target="_blank">
            confirm
          </a>{" "}
          their authorship or by their audience.
          <br />
          Thank you for linking channels!
        </Notice>

        <footer>
          <SubmitButton onClick={() => close(channel)}>
            {isDomain ? <>{channel.id}</> : <>{channel.alias_host}</>}
          </SubmitButton>
          <SubmitButton
            onClick={() => {
              channel.id += "!"
              close(channel)
            }}
            style={{ marginLeft: "10px" }}
          >
            Super Channel
          </SubmitButton>
        </footer>
      </>

      <style jsx>{`
        .owner {
          width: 100%;
          display: flex;
          margin-bottom: 10px;
          margin-top: 10px;
          padding: 5px;
          /*background-color: ${css.backgroundColor};*/
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;
        }

        .handle {
          color: rgb(101, 119, 134);
          margin-left: 4px;
        }
        strong {
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          background-color: rgba(105, 105, 105, 0.05);
          padding: 5px;
          border-radius: 5px;
        }
        strong:hover {
          background-color: rgba(105, 105, 105, 0.2);
          cursor: pointer;
        }

        .inline {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
        }
        .close:hover {
          cursor: pointer;
        }
        .highlighted {
          font-weight: 400;
          font-size: 14px;
        }

        .amountCell {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          width: 80px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          margin-top: 30px;
          text-align: right;
        }
      `}</style>
    </section>
  )
}

export default SubscribeSuperchannel
