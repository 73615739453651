import { faQuestionSquare, faTimes } from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, withStyles } from "@material-ui/core"
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from "react"
import { showToast } from "../../components/status-notification"
import { useStyle } from "../../hooks/use-style"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { useNode } from "../../hooks/use-node"
import { darkTheme, lightTheme } from "../../services/theme"
import { Channel, Claim } from "../../types"
import SubmitButton from "../layout/form/submit-button"

const WideHtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#ffe180",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 600,
    padding: 10,
    fontSize: 12,
    /*border: "1px solid #dadde9",*/
  },
}))(Tooltip)

type Props = {
  channel: Channel
  close: () => void
}

const periods = [
  { id: 0, label: "week" },
  { id: 1, label: "month" },
  { id: 2, label: "quarter" },
  { id: 3, label: "year" },
]

const SupportChannel: FunctionComponent<Props> = ({ channel, close }) => {
  const { user, addRepeatingDonation, updateBalance } = useUser()
  const [formComplete, setFormComplete] = useState(false)
  const [amount, setAmount] = useState(0)
  const [threshold, setThreshold] = useState(0)
  const [period, setPeriod] = useState(1)
  const [comment, setComment] = useState("")
  const { rpc } = useNode()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    switchOutlineColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      let donationAmount = amount //Math.floor(amount)
      let subscription = {
        recipient: channel.alias,
        amount: donationAmount,
        threshold: threshold,
        currency: user?.displayed_currency,
        title: user && user.profile ? user.profile.title : "",
        avatar: user && user.profile ? user.profile.image : "",
        comment: comment,
      }

      rpc(
        "add_recurring_donation",
        JSON.stringify(subscription),
        user,
        getCachedSecret(),
        function (res) {
          let message
          if (res.status == "ok") {
            message = "Recurring donation added"
            updateBalance(-donationAmount)
            addRepeatingDonation(channel, donationAmount, period)
          } else message = "Failed to add recurring donation"

          showToast(faInfoCircle, "Smartlike network", message)
        }
      )
      close()
    },
    [
      user,
      channel,
      amount,
      period,
      comment,
      addRepeatingDonation,
      updateBalance,
    ]
  )

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length == 0) {
      setAmount(0)
    } else {
      let amount = parseFloat(event.currentTarget.value)
      if (amount < 0) amount = 0
      setAmount(amount)
    }
  }, [])

  const handleThresholdChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value.length == 0) {
        setThreshold(0)
      } else {
        let amount = parseFloat(event.currentTarget.value)
        if (amount < 0) amount = 0
        setThreshold(amount)
      }
    },
    []
  )

  const changeComment = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.currentTarget.value)
  }, [])

  const parseAlias = (channel: Channel | null) => {
    if (channel && channel.alias && channel.alias.length) {
      let p = channel.alias.split("#")
      if (p.length > 1) return [p[0], p[1]]
    }
    return ["", ""]
  }

  const [name, publisher] = parseAlias(channel)

  const onPeriodChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setPeriod(event.currentTarget.value)
    },
    []
  )

  return (
    <section>
      <div style={{ float: "right", color: "grey" }}>
        <div
          onClick={() => {
            close()
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>
      </div>

      <h1>Set up recurring donation</h1>

      <form onSubmit={onSubmit}>
        <div style={{ width: "100%" }}>
          <div
            className="trow"
            style={{ marginBottom: "10px", width: "250px" }}
          >
            <div className="tcell" style={{ width: "70px" }}>
              Amount
            </div>
            <div className="amountCell">
              <div style={{ width: "100px", display: "inline-flex" }}>
                <input
                  id="amount"
                  type="number"
                  step="0.01"
                  value={amount}
                  onChange={handleChange}
                  className="amount"
                />
              </div>
              per month
            </div>
          </div>

          <div className="trow">
            <div className="tcell" style={{ width: "70px" }}>
              Comment
            </div>
            <div className="commentCell">
              <input
                id="comment"
                type="text"
                value={comment}
                onChange={changeComment}
                className="comment"
              />
            </div>
          </div>

          <div
            className="trow"
            style={{ marginBottom: "10px", width: "250px" }}
          >
            <div className="tcell" style={{ width: "70px" }}>
              Threshold
            </div>
            <div className="amountCell">
              <div style={{ width: "150px", display: "inline-flex" }}>
                <input
                  id="threshold"
                  type="number"
                  step="1"
                  value={threshold}
                  onChange={handleThresholdChange}
                  className="amount"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              lineHeight: "18px",
              fontSize: "14px",
            }}
          >
            The amount will be transferred from your Smartlike account to the
            channel once a month.
            <br />
            Should your balance not suffice, the transfer will be skipped for
            the period. You can cancel the recurring donation anytime.
          </div>

          <div>
            If you set a threshold, your regular donation will only be billed if
            total creator's donations received in the current month do not
            exceed it. Otherwise, the month is skipped.
          </div>

          <div style={{ marginTop: "10px" }}>
            The first donation will be transferred on submit.
          </div>

          {/*
          <div className="inline">
            <div>Period</div>
            <div className="amountCell">
              <Select value={period} onChange={onPeriodChange}>
                {periods.map(c => (
                  <option key={c.id} value={c.id}>
                    {c.label}
                  </option>
                ))}
              </Select>
            </div>
                </div>*/}
        </div>

        <footer>
          <SubmitButton>Submit</SubmitButton>
        </footer>
      </form>

      <style jsx>{`
        .inline {
          display: table-row;
          /*align-items: center;*/
          /*justify-content: space-between;*/
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
          height: 40px;
        }
        .tcell {
          display: table-cell;
          vertical-align: middle;
        }
        .close:hover {
          cursor: pointer;
        }
        .highlighted {
          font-weight: 400;
          font-size: 14px;
        }

        .amountCell,
        .commentCell {
          display: table-cell;
          vertical-align: middle;
          padding-left: 10px;
          width: 120px;
        }

        .commentCell {
          width: 100%;
        }

        .amount,
        .comment {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover,
        .commetn:focus,
        .comment:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .amount {
          margin-right: 10px;
        }

        .comment {
          text-align: left;
          width: 100%;
        }

        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
          font-weight: 500;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
        }
      `}</style>
    </section>
  )
}

export default SupportChannel
