import {
  faInfinity,
  faTrafficLightStop,
  faBalanceScaleLeft,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useEffect, useRef } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useUser } from "../../hooks/use-user"
import { useStyle } from "../../hooks/use-style"
import { darkTheme, lightTheme } from "../../services/theme"
import { useSideMode, SideMode } from "../../hooks/use-side-mode"
import HtmlTooltip from "../widgets/html-tooltip"
import { useUpdate } from "../../hooks/use-update"

const SortFilter: FunctionComponent = () => {
  const { filters, updateFilters } = useFilters()
  const { sideMode } = useSideMode()
  const { user } = useUser()
  const { setSidePanelClosed } = useUpdate()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,

    activeColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
  }, [])

  const updatePeriod = useCallback(
    (period: any) => {
      setSidePanelClosed(false)
      if (filters.sort == "amount") updateFilters(period)
      else updateFilters({ ...period, sort: "amount" })
    },
    [filters, setSidePanelClosed]
  )

  const updateFiltersLocal = useCallback(
    (update: any) => {
      setSidePanelClosed(false)
      updateFilters(update)
    },
    [filters, setSidePanelClosed]
  )

  const changeMod = useCallback(
    (m: number) => {
      setSidePanelClosed(false)
      //console.log("changeMod")
      updateFilters({ mod: filters.mod != m ? m : 0 })
    },
    [filters, setSidePanelClosed]
  )

  const hydrationBugWorkaroundKey = firstUpdate.current ? `client` : `server`

  //console.log("sort filter mode " + sideMode)

  return (
    <>
      {sideMode == SideMode.Content && (
        <div className="accordion">
          <section>
            <header>
              <div style={{ display: "inlineFlex" }}>
                <ul className="filterConrols">
                  <li
                    className={
                      filters.sort === "date"
                        ? "controlItem selected"
                        : "controlItem"
                    }
                    onClick={() => updateFiltersLocal({ sort: "date" })}
                    key={"date" + hydrationBugWorkaroundKey}
                  >
                    Latest
                  </li>
                  <li
                    className={
                      filters.sort === "amount"
                        ? "controlItem selected"
                        : "controlItem"
                    }
                    onClick={() => updateFiltersLocal({ sort: "amount" })}
                    key={"amount" + hydrationBugWorkaroundKey}
                  >
                    Top
                  </li>
                </ul>
                {user && user.state >= 10 && (
                  <div
                    className=""
                    style={{
                      marginLeft: "5px",
                      display: "inline-flex",
                      verticalAlign: "middle",
                    }}
                  >
                    {user.state >= 10 && (
                      <div
                        className={
                          filters.mod === 1
                            ? "controlIcon onair"
                            : "controlIcon"
                        }
                        onClick={() => changeMod(1)}
                        key={"mod1" + hydrationBugWorkaroundKey}
                      >
                        <div className="thumbsUp">
                          <FontAwesomeIcon
                            icon={faTrafficLightStop}
                            size="lg"
                          />
                        </div>
                      </div>
                    )}
                    {user.state >= 10 && (
                      <div
                        className={
                          filters.mod === 2
                            ? "controlIcon disputed"
                            : "controlIcon"
                        }
                        onClick={() => changeMod(2)}
                        key={"mod2" + hydrationBugWorkaroundKey}
                      >
                        <div className="thumbsUp">
                          <FontAwesomeIcon
                            icon={faBalanceScaleLeft}
                            size="lg"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <HtmlTooltip title="Show my likes only" placement="top">
                  <ul className="filterConrols" style={{ float: "right" }}>
                    <li
                      className={
                        filters.liked === true
                          ? "controlItem selected"
                          : "controlItem"
                      }
                      onClick={() =>
                        updateFiltersLocal({ liked: !filters.liked })
                      }
                      key={"liked" + hydrationBugWorkaroundKey}
                    >
                      My Likes
                    </li>
                  </ul>
                </HtmlTooltip>
              </div>
              <div
                style={{
                  display: "inlineFlex",
                  /*marginLeft: "70px"*/
                  height: "40px",
                }}
              >
                {filters.sort === "amount" && (
                  <>
                    <ul
                      className="filterConrols"
                      style={{ marginTop: "8px", marginLeft: 0, width: "80%" }}
                    >
                      <li
                        className={
                          filters.age === 24
                            ? "periodControlItem selected"
                            : "periodControlItem"
                        }
                        onClick={() => updatePeriod({ age: 24 })}
                        key={"day" + hydrationBugWorkaroundKey}
                      >
                        day
                      </li>
                      <li
                        className={
                          filters.age === 24 * 7
                            ? "periodControlItem selected"
                            : "periodControlItem"
                        }
                        onClick={() => updatePeriod({ age: 24 * 7 })}
                        key={"week" + hydrationBugWorkaroundKey}
                      >
                        week
                      </li>
                      <li
                        className={
                          filters.age === 24 * 30
                            ? "periodControlItem selected"
                            : "periodControlItem"
                        }
                        onClick={() => updatePeriod({ age: 24 * 30 })}
                        key={"month" + hydrationBugWorkaroundKey}
                      >
                        month
                      </li>
                      <li
                        className={
                          filters.age === 8760
                            ? "periodControlItem selected"
                            : "periodControlItem"
                        }
                        onClick={() => updatePeriod({ age: 8760 })}
                        key={"year" + hydrationBugWorkaroundKey}
                      >
                        year
                      </li>
                      <li
                        className={
                          filters.age === 0
                            ? "periodControlItem selected"
                            : "periodControlItem"
                        }
                        onClick={() => updatePeriod({ age: 0 })}
                        key={"all" + hydrationBugWorkaroundKey}
                      >
                        <FontAwesomeIcon
                          icon={faInfinity}
                          size="sm"
                          style={{ marginTop: "2px" }}
                        />
                      </li>
                    </ul>
                    <HtmlTooltip
                      title="Show chart newcomers only"
                      placement="bottom"
                    >
                      <ul
                        className="filterConrols"
                        style={{ float: "right", marginTop: "8px" }}
                      >
                        <li
                          className={
                            filters.nc === true
                              ? "controlItem selected"
                              : "controlItem"
                          }
                          onClick={() =>
                            updateFiltersLocal({ nc: !filters.nc })
                          }
                          key={"nc" + hydrationBugWorkaroundKey}
                          style={{ height: "32px", fontSize: "12px" }}
                        >
                          new
                        </li>
                      </ul>
                    </HtmlTooltip>
                  </>
                )}
              </div>
            </header>
          </section>

          <style jsx>{`
            header {
              display: block;
              justify-content: space-between;
              align-items: center;
              padding: 10px;
              font-family: Roboto;
            }
            h1 {
              margin: 0;
              padding: 0;
              font-size: inherit;
              font-style: inherit;
              font-weight: normal;
              display: flex;
              align-items: center;
              text-transform: uppercase;
              font-weight: lighter;
            }

            .accordion {
              background-color: ${css.backgroundColor};
              border: 1px solid ${css.borderColor};
              transition: 200ms background-color, 200ms border-color;
              border-radius: 0.3em;
              font-size: 0.9em;
              user-select: none;
            }
            section:not(:first-child) {
              border-top: 1px solid ${css.borderColor};
              transition: 200ms border-color;
            }
            .content {
              padding: 5px;
              border-top: 1px solid ${css.borderColor};
              flex-wrap: wrap;
              display: inline-flex;
            }
            .tag {
              background-color: rgb(105, 105, 105, 0.1);
              padding: 5px;
              margin-right: 5px;
              margin-bottom: 5px;
              border-radius: 5px;
              font-size: 14px;
              word-break: keep-all;
              user-select: none;
            }
            .tag:hover {
              background-color: rgb(105, 105, 105, 0.3);
              cursor: pointer;
            }
            .filterConrols {
              display: inline-flex;
              border: 1px solid ${css.borderColor};
              border-radius: 20px;
              align-items: center;
              margin: 0;
              padding: 0;
              list-style: none;
              background-color: ${css.inputBackground};
            }

            .controlIcon {
              display: inline-flex;
              color: rgb(108, 121, 127);
              height: 40px;
              width: 40px;
              margin-left: 3px;
            }
            .thumbsUp {
              margin: auto;
            }
            .thumbsUp:hover {
              color: blue;
              cursor: pointer;
            }

            .controlItem {
              padding: 0 15px;
              position: relative;
              display: block;
              margin: 0;
              height: 100%;

              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              position: relative;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: -1px;
              height: 40px;
              border: 2px solid transparent;
              border-radius: 3px;
              color: #6c797f;
              font-weight: 700;
              font-size: 13px;
              font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI",
                Arial, sans-serif;
              text-decoration: none;
              cursor: pointer;
            }
            .periodControlItem {
              width: 25%;
              position: relative;
              display: block;
              margin: 0;
              height: 100%;

              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              position: relative;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: -1px;
              height: 32px;
              border: 2px solid transparent;
              border-radius: 3px;
              color: #6c797f;
              font-weight: 700;
              font-size: 12px;
              font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI",
                Arial, sans-serif;
              text-decoration: none;
              cursor: pointer;
            }

            .selected {
              /*border: 1px solid ${css.activeColor};*/
              border-radius: 20px;
              background-color: rgb(251, 230, 162);
            }
            .onair {
              border: 1px solid ${css.activeColor};
              border-radius: 20px;
              /*background-color: #ff000052;*/
              background-color: #ffff0085;
            }

            .disputed {
              border: 1px solid ${css.activeColor};
              border-radius: 20px;
              background-color: #ff00002e;
            }

            .filterAction {
              font-size: 20px;
            }
            .filterAction:hover {
              cursor: pointer;
            }
          `}</style>
        </div>
      )}
    </>
  )
}

export default SortFilter
