import {
  faBookmark,
  faChevronSquareDown,
  faChevronSquareUp,
  faUnlink,
  faBroadcastTower,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent, useCallback, useState } from "react"
import { useFilters } from "../../hooks/use-filters"
import { useStyle } from "../../hooks/use-style"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import { useSideMode, SideMode } from "../../hooks/use-side-mode"
import { useUpdate } from "../../hooks/use-update"
import { navigate } from "gatsby"
import { useNode } from "../../hooks/use-node"
import { showToast } from "../status-notification"

type Props = {
  channel: string
  superChannel: string
}

const SuperChannel: FunctionComponent<Props> = ({ channel, superChannel }) => {
  const { filters, updateFilters } = useFilters()
  const { sideMode } = useSideMode()
  const { setSidePanelClosed } = useUpdate()
  const [showChannels, setShowChannels] = useState(false)
  const [channels, setChannels] = useState([])
  const { rpc } = useNode()

  const {
    user,
    selectBookmark,
    removeBookmark,
    showBookmarks,
    setShowBookmarks,
  } = useUser()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const selectChannel = (id: number) => {
    navigate("/channel/" + id)
  }

  const _removeBookmark = useCallback(
    async (e, id: number) => {
      removeBookmark(id)
      e.preventDefault()
    },
    [removeBookmark, filters]
  )

  const removeChannel = useCallback(
    (e, channelId: string) => {
      let sc = channel
      if (sc.length != 0) {
        if (sc.indexOf("!") > 0) {
          sc = sc.substring(0, sc.length - 1)
        }
        console.log(channelId + " => " + sc)

        let payload = {
          alias: channelId,
          superchannel: sc,
          pro: false,
        }

        console.log(JSON.stringify(payload))
        //return

        rpc(
          "link_channel",
          JSON.stringify(payload),
          user,
          getCachedSecret(),
          function (res) {
            let message
            if (res.status == "ok") {
              message = "Channel connection change proposed. Thank you!"
              //if (user) updateBalance(-0.01) // todo: update
            } else message = "Edit failed"

            showToast(faInfoCircle, "Smartlike network", message)
          }
        )
      }
      e.stopPropagation()
      e.preventDefault()
    },
    [channel, user]
  )

  const toggleShowChannels = useCallback(async () => {
    const show = !showChannels
    if (show && channels.length == 0) {
      const response = await Promise.resolve(
        fetch("/api/v2/super/" + superChannel)
          .then(res => res.text())
          .catch(error => {
            console.log("json parsing error occured ", error)
            return null
          })
      )

      //console.log(response)
      if (response) {
        let r = JSON.parse(response)
        console.log(r)
        for (let i = 0; i < r.length; i++) {
          r[i] = JSON.parse(r[i])
          r[i]["meta"] = JSON.parse(r[i]["meta"])

          let p = r[i]["meta"]["alias"].split("#")
          if (p?.length == 2) {
            r[i]["meta"]["host"] = p[1]
          }
        }
        //console.log(r)
        setChannels(r)
      }
    }
    setShowChannels(show)
  }, [superChannel, showChannels, channels, setChannels])

  return (
    <>
      {true && (
        <div className="accordion">
          <section>
            <header onClick={toggleShowChannels} className="header">
              <h1>
                <FontAwesomeIcon icon={faBroadcastTower} size="lg" />
                <span>Linked channels</span>
              </h1>
              {showChannels ? (
                <div className="filterConrols">
                  <div className="filterAction">
                    <FontAwesomeIcon icon={faChevronSquareUp} />
                  </div>
                </div>
              ) : (
                <div className="filterConrols">
                  <div className="filterAction">
                    <FontAwesomeIcon icon={faChevronSquareDown} />
                  </div>
                </div>
              )}
            </header>
          </section>
          {showChannels && (
            <section>
              <div className="content">
                {channels.map(c => (
                  <div
                    key={c.id}
                    className="bookmark"
                    onClick={() => selectChannel(c.id)}
                  >
                    <div className="tags">
                      <div className="tags-inner">
                        {typeof c.meta.host !== "undefined" ? (
                          <div className="tag">
                            {c.meta.title + " on " + c.meta.host}
                          </div>
                        ) : (
                          <div className="tag">{c.meta.alias}</div>
                        )}
                      </div>
                    </div>
                    <div className="delete-bookmark">
                      <div style={{ padding: "9px" }}>
                        <FontAwesomeIcon
                          onClick={e => removeChannel(e, c.id)}
                          icon={faUnlink}
                          style={{
                            fontSize: "12px",
                            float: "right",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
          <style jsx>{`
            .header {
            }
            .header:hover {
              cursor: pointer;
            }
            .delete-bookmark {
              /*height: 20px;
            vertical-align: middle;
            font-size: 12px;
            float: right;*/
              width: 20px;
              display: table-cell;
              z-index: 10000;
              opacity: 0.5;
            }
            .delete-bookmark:hover {
              color: blue;
              opacity: 1;
              z-index: 10000;
            }

            .bookmark {
              display: table;
              width: 100%;
              /*padding-top: 5px;*/
              padding-top: 1px;
              padding-bottom: 1px;
            }
            .bookmark:hover {
              /*background-color: rgb(249, 249, 249);*/
              background-color: rgba(105, 105, 105, 0.15);
              cursor: pointer;
              border-radius: 4px;
            }

            @media (hover: hover) and (pointer: fine) {
              .delete-bookmark {
                visibility: hidden;
              }

              .bookmark:hover .delete-bookmark {
                visibility: visible;
              }
            }

            .tags {
              display: table-cell;
              padding-left: 5px;
            }
            .tags-inner {
              display: flex;
              flex-flow: wrap;
            }
            header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              /*padding: 1em;*/
              padding-top: 1em;
              font-family: Roboto;
            }
            header > :global(:not(:first-child)) {
              margin-left: 1em;
            }
            h1 {
              margin: 0;
              padding: 0;
              font-size: inherit;
              font-style: inherit;
              font-weight: normal;
              display: flex;
              align-items: center;
              text-transform: uppercase;
              font-weight: lighter;
            }
            h1 > *:not(:first-child) {
              margin-left: 1em;
            }

            .accordion {
              background-color: ${css.backgroundColor};
              /*border: 1px solid ${css.borderColor};*/
              transition: 200ms background-color, 200ms border-color;
              border-radius: 0.3em;
              font-size: 0.9em;
              user-select: none;
            }
            /*
          section:not(:first-child) {
            border-top: 1px solid ${css.borderColor};
            transition: 200ms border-color;
          }*/
            .content {
              /*padding: 5px;*/
              margin-top: 1em;
              border-top: 1px solid ${css.borderColor};
              font-family: "Roboto";
              /*flex-wrap: wrap;
            display: inline-flex;*/
            }
            .tag {
              background-color: rgb(105, 105, 105, 0.1);
              padding: 5px;
              margin-right: 5px;
              margin-bottom: 5px;
              border-radius: 5px;
              font-size: 14px;
              word-break: keep-all;
              user-select: none;
            }
            .filterConrols {
              flex-wrap: wrap;
              display: inline-flex;
              opacity: 0.7;
            }
            .filterAction {
              font-size: 20px;
            }
            .filterAction:hover {
              cursor: pointer;
            }
          `}</style>
        </div>
      )}
    </>
  )
}

export default SuperChannel
